<template>
  <div>
    <h2>Send out announcement</h2>
    <div class="card">
      <div class="card-body">
        <div class="alert alert-info">
          <p>Send an email to all members of a selected group.</p>
        </div>
        <form @submit.prevent="submit">
          <div class="form-group">
            <label>Title</label>
            <input type="text" v-model="announcement.title" required placeholder="Title" class="form-control">
          </div>
          <div class="form-group">
            <label>Content</label>
            <tinymce
              id="add-course"
              htmlClass="large_editor"
              v-model="announcement.content"
              :other_options="{ height: '300px' }"
            />
          </div>
          <div class="form-group">
            <label>Target Receipients</label>
            <select v-model="announcement.target" required class="form-control">
              <option value="traders">Traders</option>
              <option value="signals">Signal Subscribers</option>
              <option value="academy">Academy Learners</option>
              <option value="all">All</option>
            </select>
          </div>
          <div class="form-group">
            <button class="btn btn-primary">Send</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      announcement: {}
    }
  },

  methods: {
    submit() {
      this.$axios.post('/api/v1/moderator/announcements', this.announcement).then(response => {
        this.$router.push({ name: 'moderator.announcements.show', params: { id: response.data.id }})
      })
    }
  }
}
</script>